<template>
  <div class="supplier" v-if="orderData">
    <div class="box" v-for="(order, index) in orderData.OrderList" :key="index">
      <van-row class="titleInfo" @click="getShopId(order.ShopId)">
        <van-col class="titleInfoName">{{ $t.meta.store }}</van-col>
        <van-col class="titleInfoP">{{ order.ShopName }}</van-col>
        <van-col class="titleInfoBack">
          <figure class="titleInfoBackBox">
            <img
              class="titleInfoBackIcon"
              src="~assets/img/orderDetails/icon_detail_right@2x.png"
            />
          </figure>
        </van-col>
      </van-row>
      <!-- <cargo-list :orderProductsList="orderData.OrderProductsList">
      </cargo-list>-->
      <div class="cagoList" v-if="order.OrderProductsList">
        <div v-for="(item, index) in order.OrderProductsList" :key="index">
          <cago :orderProduct="item"></cago>
        </div>
      </div>
      <!-- 货物信息 -->
      <van-row
        type="flex"
        align="center"
        justify="space-between"
        class="payInfo"
      >
        <van-col class="payInfoLeft">
          <p class="payInfoTitle">{{ $t.orderDetails.productTotalPrice }}</p>
        </van-col>
        <van-col class="payInfoRight">
          <p class="payInfoTotal">
            <span class="payInfoTotalLabel">{{ $t.orderDetails.total }}</span>
            <span class="payInfoTotalUnit">$</span>
            <span class="payInfoTotalNum" v-if="order.OrderDiscountedAmount">{{ order.OrderDiscountedAmount }}</span>
            <span class="payInfoTotalNum" v-else>{{ order.AllPrice }}</span>
          </p>
        </van-col>
      </van-row>
      <!-- 联系供应商-联系买家 -->
      <van-row
        type="flex"
        align="center"
        justify="end"
        class="touchBox"
        v-if="order.State == 20 || order.State == 30 || order.State == 1"
      >
        <van-col class="touchItme">
          <div class="touchState">
            <div
              v-if="order.State == 20 || order.State == 30"
              :class="order.State == 30 ? 'blue' : ''"
            >
              {{ order.StateStr }}
            </div>
          </div>
          <div class="touchButton">
            <button class="touchItmeBt" @click="actionLogistics(order)">
              {{ $t.order.logistics }}
            </button>
          </div>
        </van-col>
        <!-- <van-col class="touchItme">
          <button class="touchItmeBt">{{$t.orderDetails.contactTheShop}}</button>
        </van-col>-->
      </van-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
// import CargoList from '@components/common/CargoList'
import Cago from "@components/common/Cago";
Vue.use(Row).use(Col);
export default {
  name: "Supplier",
  props: ["orderData"],
  components: {
    Cago,
  },
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      let string = "";
      if (value.indexOf("|") === -1) {
        string = this.$api.formatImageUrl(value);
      } else {
        let list = value.split("|");
        string = this.$api.formatImageUrl(list[0]);
      }
      return string;
    },
    getShopId(ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "store",
        name: "store",
        query: [
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
    actionLogistics(order) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: `orderLogistics/${order.OrderNumber}`,
        name: "OrderLogistics",
        query: [
          {
            name: "AllOrderNumber",
            value: this.orderData.AllOrderNumber,
          },
          {
            name: "PostNumber",
            value: order.OrderProductsList[0].PostNumber,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/var";
@import "~assets/scss/publicStyle.scss";
@mixin publicSpan {
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-top: 15px;
}
.supplier {
  width: 100%;
  .box {
    width: 92%;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 20px 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 12px;
    color: #000000;
    ::v-deep .cargoitem {
      padding: 15px 0;
    }
    @include publicBox;
    .titleInfo {
      padding: 10px 0;
      .titleInfoName {
        width: 25%;
        line-height: 15px;
        vertical-align: middle;
      }
      .titleInfoP {
        width: 68%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000000;
        line-height: 15px;
        vertical-align: middle;
      }
      .titleInfoBack {
        width: 7%;
        text-align: right;
        line-height: 15px;
        vertical-align: middle;
        .titleInfoBackBox {
          margin: 0;
          padding: 0;
          text-align: right;
          @include publicFlex;
          .titleInfoBackIcon {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    .cagoList {
      margin: 0;
      padding: 0;
      list-style: none;
      background-color: #fcfcfc;
    }
    .payInfo {
      .payInfoLeft {
        .payInfoTitle {
          color: #a7a7a7;
          @include publicSpan;
        }
        .payInfoName {
          color: #000000;
          @include publicSpan;
        }
      }
      .payInfoRight {
        text-align: right;
        .payInfoTitle {
          color: #a7a7a7;
          @include publicSpan;
        }
        .payInfoName {
          color: #000000;
          @include publicSpan;
        }
        .payInfoTotal {
          @include publicSpan;
          .payInfoTotalLabel{
            margin-right: 10px;
          }
          .payInfoTotalUnit {
            font-size: 12px;
            color: #ff5852;
            font-weight: bold;
            margin-right: 6px;
          }
          .payInfoTotalNum {
            font-size: 18px;
            color: #ff5852;
            font-weight: bold;
          }
        }
      }
      .newBottom {
        padding-bottom: 10px !important;
      }
    }
    .touchBox {
      background-color: #ffffff;
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #f6f6f6;
      .touchItme {
        width: 100%;
        .touchState {
          width: 50%;
          display: inline-block;
          text-align: left;
          color: #a7a7a7;
          font-size: 14px;
          .blue {
            color: $-color-blue !important;
          }
        }
        .touchButton {
          width: 50%;
          display: inline-block;
          text-align: right;
          .touchItmeBt {
            border: none;
            border: 1px solid $-color-blue;
            color: #ffffff;
            background-color: $-color-blue;
            padding: 8px 20px;
            border-radius: 19px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
