<template>
  <div class="indentDate" v-if="orderData">
    <van-row type="flex" align="center" class="infoBox">
      <van-col class="infoTitle">{{ $t.orderDetails.orderNumber }}</van-col>
      <van-col class="infoName">{{ orderData.AllOrderNumber }}</van-col>
    </van-row>
    <van-row type="flex" align="center" class="infoBox newInfoBox">
      <van-col class="infoTitle">{{ $t.orderDetails.orderTime }}</van-col>
      <van-col class="infoNum">{{ orderData.CreateTime }}</van-col>
    </van-row>
    <van-row
      type="flex"
      align="center"
      class="infoBox newInfoBox"
      v-if="orderData.State && orderData.State != 10"
    >
      <van-col class="infoTitle">{{ $t.orderDetails.timeOfPayment }}</van-col>
      <van-col class="infoName">{{ orderData.PayTime }}</van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
import VueClipboard from "vue-clipboard2";
Vue.use(Row).use(Col).use(VueClipboard);
export default {
  name: "IndentDate",
  props: ["orderData"],
  data() {
    return {};
  },
  methods: {
    onCopy(e) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
        type: "warning",
        titleMsg: this.$t.copySuccess,
      });
    },
    onError(e) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
        type: "danger",
        titleMsg: this.$t.nonsupportCopy,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.indentDate {
  margin: 0 auto;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 20px 15px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 12px;
  color: #000000;
  width: 92%;
  @include publicBox;
  .infoBox {
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0 15px 0 15px;
    .infoTitle {
      width: 25%;
      color: #a7a7a7;
    }
    .infoName {
      width: 75%;
      color: #333333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .infoNum {
      width: 50%;
      color: #333333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .infoCopy {
      color: #ff5852;
      width: 25%;
      text-align: right;
    }
  }
  .newInfoBox {
    margin: 12px 0 0 0;
  }
}
</style>
