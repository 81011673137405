<template>
  <div class="payBottom" v-if="orderData">
    <div class="payDetails">
      <div class="content">
        <div class="title">{{ $t.order.priceDetail }}</div>
        <div class="info">
          <div class="left">
            <span>{{ $t.order.productTotal }}</span>
            <span class="count">{{
              $t.order.total(orderData.ProductTotalCount)
            }}</span>
          </div>
          <div class="right">
            <span class="symbol">$</span>
            <span class="amount">{{ orderData.ProductTotalAmount }}</span>
          </div>
        </div>
        <div class="info">
          <div class="left">{{ $t.order.totalFreight }}</div>
          <div class="right">
            <span class="symbol">$</span>
            <span class="amount">{{ orderData.AllFreight }}</span>
          </div>
        </div>
        <div class="info">
          <div class="left">{{ $t.order.discountAmount }}</div>
          <div class="right">
            <span class="discount" v-if="orderData.DiscountAmount">
              <span class="symbol">- $</span>
              <span>{{ orderData.DiscountAmount }}</span>
            </span>
            <span class="amount" v-else>
              <span class="symbol">$</span>
              <span>0.00</span>
            </span>
          </div>
        </div>
        <div class="info">
          <div class="left">{{ $t.order.allTotal }}</div>
          <div class="right">
            <span class="symbol">$</span>
            <span class="amount">{{ orderData.PayAllPrice }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 线下支付 -->
    <div class="footer" v-if="orderData.OrderPaymentType == 2">
      <!-- 待支付 -->
      <div
        class="btBox"
        v-if="orderData.State == 10 && orderData.OrderPaymentType != 2"
      >
        <button class="bt" @click="onPay(true)">{{ $t.pay.onPay }}</button>
      </div>
      <!-- 已支付 -->
      <van-cell-group v-else>
        <van-cell
          center
          class="btnCell"
          :title="$t.pay.offlinePay"
          is-link
          :value="paymentData.IsCheckStr"
          @click="offlinePay"
          :label="
            paymentData.FailureReason
              ? $t.paymentInfo.reason + ' : ' + paymentData.FailureReason
              : ''
          "
        />
      </van-cell-group>
    </div>
    <!-- 其他支付 -->
    <div class="footer" v-else>
      <!-- 待支付 -->
      <div class="btBox" v-if="orderData.State == 10">
        <button class="bt" @click="onPay(true)">{{ $t.pay.onPay }}</button>
      </div>
    </div>

    <pay-window :orderData="orderData" :paymentData="paymentData"></pay-window>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col, Popup, RadioGroup, Radio } from "vant";
import PayWindow from "@components/payWindow/PayWindow.vue";

Vue.use(Row).use(Col).use(Popup).use(Radio).use(RadioGroup);

export default {
  name: "PayBottom",
  props: ["orderData", "paymentData"],
  components: {
    PayWindow,
  },
  data() {
    return {};
  },
  mounted() {
    this.$bus.$off("payMethod").$on("payMethod", (isShow) => {
      this.onPay(isShow);
    });
  },
  methods: {
    onPay(isShow) {
      this.$bus.$emit("showPayWindow", isShow);
    },
    offlinePay() {
      var isEdit = true;
      if (this.orderData.OrderPaymentType == 0) isEdit = true;
      else if (this.orderData.OrderPaymentType == 2)
        isEdit = this.paymentData.isEdit;

      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "payment",
        name: "Payment",
        query: [
          {
            name: "OrderNumber",
            value: this.orderData.AllOrderNumber,
          },
          {
            name: "PayAllPrice",
            value: this.orderData.PayAllPrice,
          },
          {
            name: "IsEdit",
            value: isEdit,
          },
          {
            name: "PaymentData",
            value: this.paymentData,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@import "~assets/scss/var";
.payBottom {
  width: 100%;
  margin-top: 10px;
  .payDetails {
    margin: 0 auto;
    margin-top: 10px;
    padding: 20px;
    background-color: #ffffff;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 12px;
    color: #000000;
    width: 92%;
    @include publicBox;
    .content {
      .title {
        font-weight: bold;
        font-size: 15px;
        padding-bottom: 15px;
        border-bottom: 0.5px solid $-color-background;
      }
      .info {
        padding-top: 15px;
        .left {
          font-size: 14px;
          width: 50%;
          display: inline-block;
          .count {
            margin-left: 10px;
            font-size: 12px;
            color: $-color-light-gray;
          }
        }
        .right {
          width: 50%;
          display: inline-block;
          text-align: right;
          .symbol {
            font-size: 12px;
            margin-right: 5px;
          }
          .amount {
            font-size: 14px !important;
          }
          .discount {
            font-size: 14px !important;
            color: $-color-red;
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    padding-bottom: env(safe-area-inset-bottom);
    border-top: 1px solid #e5e5e5;
    .van-cell-group {
      border-bottom: 1px solid #e5e5e5;
    }
    .btBox {
      width: 92%;
      margin: 0 auto;
      text-align: center;
      padding-bottom: 15px;
      padding-top: 15px;
      .bt {
        border: none;
        background: $-color-red;
        border-radius: 20px;
        padding: 7px 0;
        color: #ffffff;
        width: 80%;
      }
    }
  }
  .btnCell {
    padding: 15px;
  }
}
</style>
