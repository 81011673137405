<template>
  <div class="cargo" v-if="orderProduct">
    <div class="cargoitem">
      <figure class="cargoImg">
        <van-image
          class="cargoImgIcon"
          :src="
            orderProduct.ProductPhoto && imgUrlFilter(orderProduct.ProductPhoto)
          "
        >
          <template v-slot:error>
            <img class="ImgIcon" src="~assets/img/other/empty.gif" />
          </template>
        </van-image>
      </figure>
      <div class="cargoInfo">
        <p class="cargoInfoTitle">{{ orderProduct.ProductName }}</p>
        <section class="cargoInfoSpec">
          <!-- <p class="cargoInfoSpecLeft">蓝色圆点 x1 120cm</p> -->
          <p class="cargoInfoSpecLeft">{{ orderProduct.ProductModelStr }}</p>
          <p class="cargoInfoSpecRight">
            ${{ orderProduct.PayProductPrice }}
            <span class="cargoInfoSpecNum">x{{ orderProduct.Number }}</span>
          </p>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col, Image } from "vant";

Vue.use(Row).use(Col).use(Image);
// import * as methodType from "@environment/method-type";
export default {
  name: "Cargo",
  props: ["orderProduct"],
  data() {
    return {};
  },
  methods: {
    // 跳转详情页
    getDetails() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "indentDetails",
        name: "indentDetails",
        query: [],
      });
    },
    imgUrlFilter(value) {
      let string = "";
      if (value.indexOf("|") === -1) {
        string = this.$api.formatImageUrl(value);
      } else {
        let list = value.split("|");
        string = this.$api.formatImageUrl(list[0]);
      }
      console.log(string);
      // return this.$api.formatImageUrl(value);
      return string;
      // console.log(value.split("|")[0]);
      // return this.$api.formatImageUrl(value)
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.cargo {
  margin: 0;
  padding: 0;
  list-style: none;
  //background-color: #fcfcfc;
  background-color: #ffffff;
  .cargoitem {
    list-style: none;
    margin: 0;
    // padding: 30px 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 15px 15px;
    border-bottom: 1px solid #f6f6f6;
    @include publicFlex;
    .cargoImg {
      margin: 0;
      padding: 0;
      width: 25%;
      @include publicFlex;
      .cargoImgIcon {
        width: 100%;
        width: 56px;
        height: 56px;
        object-fit: cover;
        -webkit-object-fit: cover;
        .ImgIcon {
          width: 56px;
          height: 56px;
          object-fit: cover;
        }
        ::v-deep .van-image__img {
          width: 56px;
          height: 56px;
          position: relative;
          object-fit: cover;
        }
        ::v-deep .van-image__error {
          width: 56px;
          height: 56px;
          position: relative;
          object-fit: cover;
        }
      }
    }
    .cargoInfo {
      width: 75%;
      .cargoInfoTitle {
        margin: 0;
        padding: 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        white-space: normal;
      }
      .cargoInfoSpec {
        margin-top: 9px;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        @include publicFlex;
        .cargoInfoSpecLeft {
          margin: 0;
          padding: 0;
          color: #a7a7a7;
          word-break: break-word;
        }
        .cargoInfoSpecRight {
          margin: 0;
          padding: 0;
          font-size: 12px;
          color: #000000;
          text-align: right;
          .cargoInfoSpecNum {
            color: #999999;
            margin-left: 6px;
          }
        }
      }
    }
  }
}
</style>
