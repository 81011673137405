<template>
  <div class="receivingInfo" v-if="usersReceiving">
    <van-row type="flex" align="center" class="user">
      <van-col class="userTitle">{{ $t.orderDetails.consignee }}</van-col>
      <van-col class="userName">{{ usersReceiving.ReceivingName }}</van-col>
      <van-col class="userPhone">{{ usersReceiving.PhoneNumber }}</van-col>
    </van-row>
    <van-row type="flex" align="center" class="address">
      <van-col class="addressTitle">{{
        $t.orderDetails.shippingAddress
      }}</van-col>
      <van-col class="addressInfo">{{
        usersReceiving.ReceivingAddress
      }}</van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
export default {
  name: "ReceivingInfo",
  props: ["usersReceiving"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.receivingInfo {
  width: 92%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 20px 15px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 12px;
  color: #000000;
  @include publicBox;
  .user {
    width: 100%;
    .userTitle {
      width: 25%;
      color: #a7a7a7;
    }
    .userName {
      width: 30%;
    }
    .userPhone {
      width: 45%;
      word-break: break-all;
    }
  }
  .address {
    width: 100%;
    margin-top: 12px;
    .addressTitle {
      width: 25%;
      color: #a7a7a7;
    }
    .addressInfo {
      width: 75%;
    }
  }
}
</style>
