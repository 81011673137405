<template>
  <van-popup v-model="onShow" position="bottom" class="pay-popup">
    <van-row align="center" class="pay-header">
      <van-col class="pay-subtitle">
        <span class="boxUnit">$</span>
        {{ orderData.PayAllPrice }}
      </van-col>
      <van-col class="pay-title">{{ $t.pay.payType }}</van-col>
    </van-row>

    <div class="payBottom" v-if="orderData && paymentData">
      <van-collapse
        v-model="activeIndex"
        accordion
        :border="false"
        @change="onChange"
      >
        <van-collapse-item
          :lazy-render="false"
          :border="false"
          v-for="(option, opIndex) in options"
          :key="opIndex"
          :name="option.id"
        >
          <template #title>
            <div class="payment-left">
              <img class="payment-icon" :src="option.icon" />
            </div>
            <div class="payment-right">
              {{ option.title }}
            </div>
          </template>
          <van-radio-group v-model="cdid">
            <van-cell-group>
              <div v-if="channelItems.length > 0">
                <van-cell
                  v-for="(item, index) in channelItems"
                  :key="index"
                  :title="
                    language == 'zh' ? item.ChannelNameCn : item.ChannelNameEn
                  "
                  clickable
                  @click="onSelect(item)"
                >
                  <template #right-icon>
                    <van-radio
                      :name="item.CDId"
                      checked-color="#71d283"
                    ></van-radio>
                  </template>
                </van-cell>
              </div>
              <div v-else class="emptyBox">
                {{ $t.pay.emptyPay }}
              </div>
            </van-cell-group>
          </van-radio-group>
        </van-collapse-item>
      </van-collapse>

      <!-- <van-radio-group v-model="radio">
        <van-cell-group>
          <img class="radio-icon" src="~assets/img/payment/wallet.png" />
          <van-cell :title="$t.pay.offlinePay" clickable @click="radio = '1'">
            <template #right-icon>
              <van-radio name="1" checked-color="#71d283"></van-radio>
            </template>
          </van-cell>

          <img class="radio-icon" src="~assets/img/payment/shop.png" />
          <van-cell :title="$t.pay.cardPay" clickable @click="radio = '2'">
            <template #right-icon>
              <van-radio name="2" checked-color="#71d283"></van-radio>
            </template>
          </van-cell>

          <img class="radio-icon" src="~assets/img/payment/shop.png" />
          <van-cell :title="$t.pay.cardPay" clickable @click="radio = '3'">
            <template #right-icon>
              <van-radio name="3" checked-color="#71d283"></van-radio>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group> -->
    </div>

    <div class="submitBox" @click="onPay">
      <div class="submit">{{ $t.pay.onPay }}</div>
    </div>
  </van-popup>
</template>
<script>
import Vue from "vue";
import { Cell, CellGroup, Popup, Collapse, CollapseItem } from "vant";
import { isAPP, getPlatform } from "@/plugins/environment/type.js";
Vue.use(Popup).use(Cell).use(CellGroup).use(Collapse).use(CollapseItem);

export default {
  name: "PayWindow",
  props: ["orderData", "paymentData"],
  data() {
    return {
      cdid: "",
      paymentLink: "",
      activeIndex: "",
      channelItems: [],
      options: [
        {
          id: "1",
          title: this.$t.pay.cardPay,
          icon: require("@/assets/img/payment/shop.png"),
        },
        {
          id: "2",
          title: this.$t.pay.offlinePay,
          icon: require("@/assets/img/payment/wallet.png"),
        },
      ],
      onShow: false,
      language: "",
    };
  },
  mounted() {
    var localLanguage =
      navigator.language ||
      navigator.userLanguage ||
      localStorage.getItem(kLanguage) ||
      "en";
    this.language = localLanguage.substr(0, 2);
  },
  created() {
    this.$bus.$off("showPayWindow").$on("showPayWindow", (isShow) => {
      this.onShow = isShow;
    });
  },
  methods: {
    onSelect(item) {
      this.cdid = item.CDId;
      this.paymentLink = item.PaymentLink;
    },
    onPay() {
      if (!this.activeIndex || !this.cdid) {
        this.$commonMethod.showToast({
          type: "error",
          titleMsg: this.$t.pay.selectPay,
        });
        return;
      }
      if (this.activeIndex == "1") {
        this.creditCardPay();
      }
      if (this.activeIndex == "2") {
        this.offlinePay();
      }
      if (this.activeIndex == "3") {
        this.cardPay();
      }
      this.onShow = false;
    },
    onChange(activeIndex) {
      this.cdid = "";
      this.activeIndex = activeIndex;
      this.getPaymentWay();
    },
    getPaymentWay() {
      this.$commonMethod.showLoading();
      let param = {
        PaymentWayClass: this.activeIndex,
      };
      this.$api.order
        .ChannelDealerListByPaymentWay(param)
        .then((res) => {
          const data = res.data;
          this.channelItems = data;
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    offlinePay() {
      var isEdit = true;
      if (this.orderData.OrderPaymentType == 0) isEdit = true;
      else if (this.orderData.OrderPaymentType == 2)
        isEdit = this.paymentData.isEdit;

      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "payment",
        name: "Payment",
        query: [
          {
            name: "OrderNumber",
            value: this.orderData.AllOrderNumber,
          },
          {
            name: "IsEdit",
            value: isEdit,
          },
          {
            name: "PaymentData",
            value: this.paymentData,
          },
          {
            name: "CDId",
            value: this.cdid,
          },
        ],
      });
    },
    cardPay() {
      var isApp = isAPP();
      var platform = getPlatform();
      this.$commonMethod.showLoading();
      let param = {
        OrderNumber: this.orderData.AllOrderNumber,
        ChannelDealerId: this.cdid,
        SystemType: isApp ? platform : "web",
      };
      this.$api.order
        .getPaymentLinks(param)
        .then((res) => {
          const data = res.data;
          if (data.Url) {
            this.$commonEnv.commonAction(this.$commonEnv.actionType.gotoPay, {
              url: data.Url,
            });
          }
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    creditCardPay() {
      var isApp = isAPP();
      var platform = getPlatform();
      this.$commonMethod.showLoading();
      let param = {
        OrderNumber: this.orderData.AllOrderNumber,
        ChannelDealerId: this.cdid,
        PaymentLink: this.paymentLink,
        SystemType: isApp ? platform : "web",
      };
      this.$api.order
        .generatePaymentLinks(param)
        .then((res) => {
          const data = res.data;
          if (data.Url) {
            this.$commonEnv.commonAction(this.$commonEnv.actionType.gotoPay, {
              url: data.Url,
            });
          }
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.pay-popup {
  height: auto;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-bottom: env(safe-area-inset-bottom);
  .pay-header {
    width: auto;
    padding: 5%;
    .pay-title {
      width: 100%;
      text-align: center;
      font-size: 12px;
    }
    .pay-subtitle {
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 2rem;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      .boxUnit {
        font-size: 1.5rem;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
  .submitBox {
    width: 100%;
    .submit {
      position: relative;
      width: 90%;
      margin: 5%;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: linear-gradient(left, #71d283, #01aaa3);
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
  }
  .van-collapse {
    .emptyBox {
      padding: 10px 16px;
    }
    ::v-deep.van-collapse-item__content {
      padding: 10px;
    }
    ::v-deep.van-cell__right-icon {
      line-height: 30px;
    }
    .payment-left {
      display: inline-block;
      margin-right: 5px;
      .payment-icon {
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }
    }
    .payment-right {
      display: inline-block;
    }
  }
}
</style>