<template>
  <div class="orderTopBox" v-if="orderData">
    <div class="orderTop">
      <div class="box">
        <div class="boxWrapper">
          <p class="boxTitle">
            {{ orderData.StateStr }}
            <span class="boxReason" v-if="orderData.OrderCancelReason"
              >( {{ orderData.OrderCancelReason }} )</span
            >
          </p>
          <div class="boxText" v-if="orderData.State == 30">
            {{ orderData.AutomaticDeliveryTimeStr }}
          </div>
          <div
            class="boxText"
            v-if="orderData.State == 10 && !orderData.OrderPaymentType"
          >
            <van-count-down ref="countDown" :time="countDown">
              <template #default="timeData">{{
                $t.orderDetails.unpayText(
                  timeData.hours +
                    ":" +
                    timeData.minutes +
                    ":" +
                    timeData.seconds
                )
              }}</template>
            </van-count-down>
          </div>
        </div>
        <figure class="logo">
          <img class="logoIcom" src="~assets/img/orderDetails/img_car@2x.png" />
        </figure>
      </div>
    </div>
    <!-- 物流状态 -->
    <!-- <div
      class="logistics"
      v-if="orderData.NewOrderLogistics"
      @click="getOrderLogistics(orderData.OrderNumber)"
    >
      <img class="logisticsLogo" src="~assets/img/orderDetails/icon_detail_logistics@2x.png" />
      <p class="state">{{$t.orderDetails.logisticsStatus}}：{{orderData.NewOrderLogistics.LStateStr}}</p>
      <img class="goLogo" src="~assets/img/orderDetails/icon_detail_right@2x.png" />
    </div>-->
  </div>
</template>
<script>
import Vue from "vue";
import { CountDown } from "vant";

Vue.use(CountDown);
export default {
  name: "Top",
  props: ["orderData"],
  data() {
    return {
      countDown: "",
    };
  },
  mounted() {
    this.$bus.$off("setTime").$on("setTime", (cancelTime) => {
      if (cancelTime) this.setTime(cancelTime);
    });
  },
  methods: {
    setTime(cancelTime) {
      var time = cancelTime.replaceAll("-", "/");
      var now = new Date().getTime();
      var end = new Date(time).getTime();
      var leftTime = end - now;
      this.countDown = leftTime;
    },
    getOrderLogistics(id) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: `orderLogistics/${id}`,
        name: "OrderLogistics",
        query: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.publicImg {
  width: 20px;
  height: 20px;
  object-fit: cover;
  -webkit-object-fit: cover;
}
.orderTopBox {
  .orderTop {
    width: 100%;
    margin: 0 auto;
    background: linear-gradient(left, #71d283, #01aaa3);
    padding: {
      top: 20px;
      bottom: 20px;
    }
    * {
      margin: 0;
      padding: 0;
    }
    .box {
      width: 80%;
      margin: 0 auto;
      position: relative;
      @include publicFlex;
      .boxWrapper {
        min-width: 60%;
        margin: 0;
        padding: 0;
        .boxTitle {
          font: {
            size: 14px;
            weight: bold;
          }
          color: #ffffff;
          .boxReason {
            width: 100%;
            display: inline-block;
          }
        }
        .boxText {
          margin-top: 0.2rem;
          color: #ffffff;
          .van-count-down {
            color: #ffffff;
            font-size: 12px;
          }
        }
      }
      .logo {
        justify-content: center;
        -webkit-justify-content: center;
        margin: 0;
        padding: 0;
        @include publicFlex;
        .logoIcom {
          width: 108px;
        }
      }
    }
  }
  .logistics {
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    border-bottom: 1px solid #ece7e7;
    padding: 5px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    @include publicFlex;
    .logisticsLogo {
      @extend .publicImg;
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    .state {
      flex: 1;
      -webkit-flex: 1;
      font-size: 14px;
      color: #000000;
    }
    .goLogo {
      @extend .publicImg;
      margin-left: 10px;
    }
  }
}
</style>
