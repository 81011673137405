<template>
  <div class="orderDetails">
    <header v-if="hideHeader">
      <van-nav-bar
        :left-arrow="true"
        :title="$t.meta.orderdetail"
        @click-left="onClickLeft"
      ></van-nav-bar>
    </header>
    <top :orderData="orderData"></top>
    <receiving-info :usersReceiving="orderData.UsersReceiving"></receiving-info>
    <supplier :orderData="orderData"></supplier>
    <indent-date :orderData="orderData"></indent-date>
    <pay-bottom :orderData="orderData" :paymentData="paymentData"></pay-bottom>
  </div>
</template>
<script>
import Top from "./components/Top";
import ReceivingInfo from "./components/ReceivingInfo";
import Supplier from "./components/Supplier";
import IndentDate from "./components/IndentDate";
import PayBottom from "./components/PayBottom";

export default {
  name: "OrderDetails",
  props: {
    id: {
      type: String,
    },
  },
  components: {
    Top,
    ReceivingInfo,
    Supplier,
    IndentDate,
    PayBottom,
  },
  data() {
    return {
      orderData: {},
      paymentData: {},
      showPayment: false,
      hideHeader: false,
      oldUrl: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.oldUrl = from.path;
    });
  },
  beforeDestroy() {
    this.hideHeader = false;
    document.getElementsByClassName("van-nav-bar")[0].style.display = "block";
  },
  mounted() {
    this.$nextTick(() => {
      //console.log(this.oldUrl);
      if (this.oldUrl == "/") {
        this.hideHeader = true;
        document.getElementsByClassName("van-nav-bar")[0].style.display =
          "none";
      } else {
        this.hideHeader = false;
        document.getElementsByClassName("van-nav-bar")[0].style.display =
          "block";
      }
    });
  },
  created() {
    const { ShowPayment } = this.$route.query;
    if (ShowPayment) {
      this.showPayment = ShowPayment;
    }
    this.loadOrderInfo();
  },
  destroyed() {
    this.showPayment = false;
  },
  methods: {
    onClickLeft() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.backUser);
    },
    // 获取订单详情
    loadOrderInfo() {
      this.$commonMethod.showLoading();
      let param = {
        AllOrderNumber: this.id,
      };
      this.$api.order
        .loadOrderInfo(param)
        .then((res) => {
          const data = res.data;
          this.orderData = data;
          this.$commonMethod.hideLoading();

          this.actionsMethod();
          if (data.OrderPaymentType == 2)
            this.getPaymentRecord(data.AllOrderNumber);
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    actionsMethod() {
      setTimeout(() => {
        if (this.orderData.State == 10) {
          //跳转支付窗口
          if (this.showPayment && this.orderData.OrderPaymentType == 0) {
            this.$bus.$emit("payMethod", true);
            this.$router.replace({ query: {} });
          }
          //设置倒计时
          if (!this.orderData.OrderPaymentType) {
            this.$bus.$emit("setTime", this.orderData.CancelOrderTime);
          }
        }
      }, 100);
    },
    getPaymentRecord(orderNumber) {
      this.$commonMethod.showLoading();
      let param = {
        OrderNumber: orderNumber,
      };
      this.$api.order
        .GetOrderLinePaymentRecord(param)
        .then((res) => {
          const data = res.data;
          this.paymentData = data;
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style scoped>
html,
body {
  height: 100%;
}
body {
  overflow: hidden;
}
header {
  height: auto;
  background: linear-gradient(left, #71d283, #01aaa3);
}
header /deep/ .van-nav-bar {
  background: transparent;
  line-height: 2.5;
}
header /deep/ .van-nav-bar__content {
}
header /deep/ .van-nav-bar__title {
  color: #fff;
  font-size: 1.2rem;
}
header /deep/ .van-nav-bar__arrow {
  color: #fff;
  font-size: 2rem;
}
header /deep/ .van-hairline--bottom::after {
  display: none;
}
.orderDetails {
  padding-bottom: 10px;
}
</style>
